import React from 'react';
import { Footer } from '../components/Footer/Footer';
import NavbarTwo from '../components/Navbar/NavbarTwo';

export default function privacy() {
    return (
        <>
            <NavbarTwo />
            <div className="privacy-policy">
                <h2>www.trentungard.com Privacy Policy</h2>
                <p>
                    This website does not natively use cookies or user specific information for anything.
                    This website does, however, use 3rd party services to enable features such as fetching my
                    latest posts from various social media accounts. This includes companies such as 
                    Facebook and Twitter. 

                    This website also uses Google Analytics so that I can monitor traffic to and on my site. In order
                    to protect your privacy, on my site your IP is anonymized. You can also opt out of this tracking below. 
                    
                    Privacy is a major concern of mine, and my websites and applciations will always be built with 
                    privacy in mind. For a detailed discussion about privacy practices, please contact me directly at trentungard@gmail.com
                </p>
                <button onClick={() => {
                    document.cookie = "ga-disable-UA-156955099-1=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/";
                    window.disableStr = 1;
                    alert("opt out successful");
                }}>Opt Out of Google Analytics Tracking</button>
            </div>
            <Footer />
        </>
    )
}
